import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "src/store";
import { activateSmButton, deactivateSmButton } from "src/store/media";

interface PlaybackContextProps {
  audioRef: React.MutableRefObject<HTMLAudioElement | null>;
  currentTrackUrl: string | null;
  play: (track: { url: string; categoryId: string }) => void;
  pause: () => void;
  togglePlayPause: (track: { url: string; categoryId?: string }) => void;
  currentTime: number;
  isPlaying: boolean;
  isLooping: boolean;
}

const PlaybackContext = createContext<PlaybackContextProps | undefined>(
  undefined
);

export const PlaybackProvider: React.FC = ({ children }) => {
  const [currentTrackUrl, setCurrentTrackUrl] = useState<string | null>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const dispatch = useDispatch();
  const { isLooping } = useSelector((state) => state.playback);
  const history = useHistory();

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    // Handler for updating current time
    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentTrackUrl(null);
      dispatch(deactivateSmButton());
    };

    const handlePlay = () => {
      setIsPlaying(true);
      const id = `magic-${currentTrackUrl}`;
      const pageUrl = history.location.pathname;
      dispatch(activateSmButton({ id, pageUrl, categoryId: 0 }));
    };

    const handlePause = () => {
      setIsPlaying(false);
      dispatch(deactivateSmButton());
    };

    // Add event listeners
    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("ended", handleEnded);
    audio.addEventListener("play", handlePlay);
    audio.addEventListener("pause", handlePause);

    // Cleanup event listeners on unmount
    return () => {
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      audio.removeEventListener("ended", handleEnded);
      audio.removeEventListener("play", handlePlay);
      audio.removeEventListener("pause", handlePause);
    };
  }, [
    dispatch,
    isLooping,
    audioRef,
    currentTrackUrl,
    history.location.pathname,
  ]);

  const playTrack = useCallback(
    ({ url, categoryId }: { url: string; categoryId: string }) => {
      if (audioRef.current) {
        // Only reset the track if we're switching to a different track
        if (currentTrackUrl !== url) {
          setCurrentTrackUrl(url);
          audioRef.current.src = url;
          audioRef.current.currentTime = 0;
        }
        // If the track is the same as the current track, don't reset currentTime.
        // Just play from where it was paused.
        audioRef.current.play();
      }
    },
    [currentTrackUrl]
  );

  const pauseTrack = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  }, []);

  const togglePlayPause = useCallback(
    ({ url, categoryId }: { url: string; categoryId?: string }) => {
      if (currentTrackUrl === url) {
        if (audioRef.current?.paused) {
          playTrack({ url, categoryId: categoryId || "" });
        } else {
          pauseTrack();
        }
      } else {
        playTrack({ url, categoryId: categoryId || "" });
      }
    },
    [currentTrackUrl, pauseTrack, playTrack]
  );

  return (
    <PlaybackContext.Provider
      value={{
        audioRef,
        currentTrackUrl,
        isPlaying,
        play: playTrack,
        pause: pauseTrack,
        togglePlayPause,
        currentTime,
        isLooping,
      }}
    >
      <audio
        ref={audioRef}
        style={{ display: "none" }}
        id={`audio-${currentTrackUrl}`}
        playsInline
        loop={isLooping}
      />
      {children}
    </PlaybackContext.Provider>
  );
};

export const usePlayback = (): PlaybackContextProps => {
  const context = useContext(PlaybackContext);
  if (!context) {
    throw new Error("usePlayback must be used within a PlaybackProvider");
  }
  return context;
};
