import React, { useEffect, useRef } from "react";
import Lottie from "lottie-react";
import animationData from "./mock_waveform.json";
import { Box } from "@chakra-ui/react";

const Waveform = ({ play }: { play: boolean }) => {
  const lottieRef = useRef<any>(null);

  useEffect(() => {
    if (play) {
      lottieRef?.current?.play();
    } else {
      lottieRef?.current?.pause();
    }
  }, [play]);

  return (
    <Box width="100%" h="150px" position="relative">
      <Lottie
        lottieRef={lottieRef}
        animationData={animationData}
        loop={true}
        autoplay={false}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -48%)",
          width: "100%",
        }}
      />
    </Box>
  );
};

export default Waveform;
