import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  useColorModeValue,
  Tag,
  useBreakpointValue,
} from "@chakra-ui/react";
import { MinusIcon, CloseIcon } from "@chakra-ui/icons";
import { FaWindowMaximize } from "react-icons/fa";
import MediaPlayer from "src/components/MediaPlayer";
import { useSelector, useDispatch } from "src/store";
import { useIntl } from "react-intl";
import { stopMedia, toggleVisible } from "src/store/media";
import PlaylistPlayer from "src/components/MediaPlayer/PlaylistPlayer/PlaylistPlayer";
import { FiClock } from "react-icons/fi";
import { FiMaximize, FiX } from "react-icons/fi";
import { motion, AnimatePresence } from "framer-motion";
import { BsMusicNoteList } from "react-icons/bs";

const MotionText = motion(Text);
const MotionFlex = motion(Flex);

function formatTime(totalSeconds: number): string {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  const hoursString = hours > 0 ? String(hours).padStart(2, "0") + ":" : "";
  const minutesString = String(minutes).padStart(2, "0");
  const secondsString = String(seconds).padStart(2, "0");

  return hoursString + minutesString + ":" + secondsString;
}

//Context for playing media in the background
const MediaBox: React.FC<{ children: any }> = ({ children }) => {
  const color = useColorModeValue("white", "gray.500");
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const setVisible = (v: boolean) => dispatch(toggleVisible({ value: v }));
  const closeMedia = () => dispatch(stopMedia());

  const user = useSelector((state) => state.auth.user);
  const type = useSelector((state) => state.media).type;
  const id = useSelector((state) => state.media.id);
  const current = useSelector((state) => state.media.current);
  const currentGif = useSelector((state) => state.media.currentGif);
  const visible = useSelector((state) => state.media.visible);
  const loopMedia = useSelector((state) => state.media.loop);
  const disableSeeking = useSelector((state) => state.media.disableSeeking);
  const name = useSelector((state) => state.media.name);
  const entityName = useSelector((state) => state.media.entityName);
  const isPlaylist = useSelector((state) => state.media.isPlaylist);
  const playlistCurrentTrackDuration = useSelector(
    (state) => state.media.playlistCurrentTrackDuration
  );
  const playlistCurrentTrackProgress = useSelector(
    (state) => state.media.playlistCurrentTrackProgress
  );

  const showModal =
    type === "video" ||
    ("" + id).includes("slf") ||
    ("" + id).includes("chakra") ||
    type === "playlistAudio";

  const isMaximized = (visible && current && showModal) as boolean;

  const [isProgressVisible, setIsProgressVisible] = useState(false);
  const [textDir, setTextDir] = useState<"rtl" | "ltr">("ltr");

  useEffect(() => {
    setTextDir(locale === "ar" ? "rtl" : "ltr");
  }, [locale]);

  // Detect mobile device
  const isMobile = useBreakpointValue({ base: true, md: false });

  if (!user) return children;
  return (
    <>
      <div
        style={{
          display: current && showModal ? "block" : "none",
        }}
      >
        <Box
          id="video-parent"
          position="fixed"
          top={isMaximized ? "50%" : "100%"}
          left="50%"
          transform={
            isMaximized ? "translate(-50%, -50%)" : "translate(-50%, -100%)"
          }
          backgroundColor={color}
          padding={["10px", "5px", "15px"]}
          maxHeight={"100vh"}
          borderTop={
            isPlaylist && !isMaximized ? "solid #d5d5d5 1px" : undefined
          }
          boxShadow={
            !isMaximized ? "0 0 10px 0 #d5d5d5" : "0 0 10px 2px silver"
          }
          zIndex="100"
          maxWidth="100vw"
          width={isMaximized ? "" : "75%"}
          minWidth="200px"
          onMouseEnter={() => {
            if (isPlaylist && !isMaximized) setIsProgressVisible(true);
          }}
          onMouseLeave={() => {
            if (isPlaylist && !isMaximized) setIsProgressVisible(false);
          }}
        >
          {isPlaylist && !isMaximized && !visible && (
            <Flex
              alignItems={"center"}
              justifyContent={"space-between"}
              mx={3}
              my={1}
            >
              <Text
                size={"md"}
                textTransform={"capitalize"}
                fontWeight={"medium"}
                fontSize={"lg"}
              >
                {formatMessage({
                  id: entityName.replace(/([a-z])([A-Z])/g, "$1 $2"),
                })}
              </Text>
              <Flex gap={2} alignItems={"center"} justifyContent={"center"}>
                <FiMaximize
                  size={20}
                  cursor="pointer"
                  onClick={() => setVisible(true)}
                />
                <FiX size={25} cursor="pointer" onClick={() => closeMedia()} />
              </Flex>
            </Flex>
          )}
          <Flex flexDir="column">
            {isMaximized && (
              <Box ml="3" d="flex" justifyContent={"flex-end"}>
                {visible ? (
                  <MinusIcon
                    width="15px"
                    height="15px"
                    margin={"0 10px"}
                    cursor="pointer"
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <FaWindowMaximize
                    width="10px"
                    height="10px"
                    cursor="pointer"
                    onClick={() => setVisible(true)}
                  />
                )}
                <CloseIcon
                  width="15px"
                  height="15px"
                  margin={"0 10px"}
                  cursor="pointer"
                  onClick={() => closeMedia()}
                />
              </Box>
            )}
            {currentGif && currentGif !== "" && isMaximized ? (
              currentGif.endsWith(".gif") ? (
                <Image
                  src={currentGif}
                  sx={{
                    pointerEvents: "none",
                    maxHeight: "400px",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <MediaPlayer
                  isMaximized={isMaximized}
                  src={currentGif}
                  loop={true}
                  noControls={true}
                />
              )
            ) : null}
            <Box
              d="flex"
              flexWrap={"wrap"}
              flexDir={isMaximized ? "column" : "row"}
              mx="auto"
              alignItems={"center"}
              justifyContent="space-evenly"
              width="100%"
            >
              <Flex
                alignItems={"start"}
                flex={!isMaximized && isPlaylist ? 1 : undefined}
                width={"100%"}
              >
                <Box
                  alignItems="center"
                  justifyContent="start"
                  marginTop={isMaximized ? "10px" : 0}
                  mr="auto"
                  d="flex"
                  flexWrap={isMaximized ? "wrap" : "nowrap"}
                >
                  {isPlaylist && !isMaximized && entityName && (
                    <Box>
                      <Box>
                        <Flex
                          alignItems={"center"}
                          justifyContent={"start"}
                          mx={3}
                          gap={2}
                        >
                          <BsMusicNoteList color="#999" />
                          <Text
                            size="sm"
                            color={"#999"}
                            dir={entityName === "solfeggio" ? "ltr" : textDir}
                          >
                            {`${name} ${
                              entityName === "solfeggio" ? "Hz" : ""
                            }`}
                          </Text>
                        </Flex>
                        {!isMobile && !isMaximized && isPlaylist && (
                          <Flex alignItems="center" overflow="hidden" mx={3}>
                            {!isMobile && !isMaximized && isPlaylist && (
                              <FiClock
                                color="#999"
                                style={
                                  locale === "en"
                                    ? { marginRight: "8px" }
                                    : { marginLeft: "8px" }
                                }
                              />
                            )}
                            <AnimatePresence initial={false}>
                              {isProgressVisible && (
                                <MotionFlex
                                  key="progress-container"
                                  initial={{ width: 0 }}
                                  animate={{ width: "auto" }}
                                  exit={{ width: 0 }}
                                  transition={{ duration: 0.3 }}
                                  alignItems="center"
                                  overflow="hidden"
                                >
                                  <Text size="sm" color={"#999"}>
                                    {formatTime(playlistCurrentTrackProgress)}
                                  </Text>
                                  <Text size="sm" color={"#999"} mx="1">
                                    /
                                  </Text>
                                </MotionFlex>
                              )}
                            </AnimatePresence>
                            <MotionText
                              size="sm"
                              color={"#999"}
                              transition={{ duration: 0.3 }}
                            >
                              {formatTime(playlistCurrentTrackDuration)}
                            </MotionText>
                          </Flex>
                        )}

                        {isMobile && !isMaximized && isPlaylist && (
                          <Flex alignItems={"center"} gap={2} mx={3}>
                            <FiClock color="#999" />
                            <Text size="sm" color={"#999"}>
                              {formatTime(playlistCurrentTrackDuration)}
                            </Text>
                          </Flex>
                        )}
                      </Box>
                    </Box>
                  )}
                  {isMaximized && (
                    <Text>{`${name} ${
                      entityName === "solfeggio" ? "Hz" : ""
                    }`}</Text>
                  )}
                  {isMaximized && entityName ? (
                    <Tag
                      size={"md"}
                      borderRadius="full"
                      variant="solid"
                      mx="3"
                      color="white"
                      textTransform={"capitalize"}
                      minWidth="fit-content"
                    >
                      {formatMessage({
                        id: entityName.replace(/([a-z])([A-Z])/g, "$1 $2"),
                      })}
                    </Tag>
                  ) : null}
                </Box>
              </Flex>
              <MediaPlayer
                disableSeeking={disableSeeking}
                src={current}
                loop={loopMedia}
                key={current}
                isMaximized={isMaximized}
                styles={{
                  minWidth: "185px",
                }}
              />
            </Box>
          </Flex>
        </Box>
        <PlaylistPlayer />
      </div>
      {children}
    </>
  );
};

export default MediaBox;
