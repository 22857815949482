import { BoxProps, HStack, IconButton, LayoutProps, Text } from "@chakra-ui/react";
import React from "react";
import { useIntl } from "react-intl";
import { RiDeleteBin6Line, RiShareBoxLine } from "react-icons/ri";
import { BiCopy } from "react-icons/bi";
import { FaRegFolderOpen } from "react-icons/fa6";
import { deleteMethod } from "src/api";
import DeleteButton from "../DeleteModel";
import { useQueryClient } from "react-query";
import Card from "src/components/Card";

type NewItemCardProps = BoxProps & {
  title?: string;
  remove?: any;
  share?: Function;
  edit?: Function;
  onCardCLick?: Function;
  newItem?: { text: string; onClick: Function };
  hoverStyles?: boolean;
  deleteIconVisibility?: LayoutProps["visibility"];
  shareIconVisibility?: LayoutProps["visibility"];
  isSharedWithMe?: boolean;
  douplicate?: () => any;
  counterText?: string;
  openChangeFolderModal?: () => void;
  shareId?: number;
};

const NewItemCard: React.FC<NewItemCardProps> = ({
  children,
  title,
  remove,
  share,
  edit,
  newItem,
  onCardCLick,
  hoverStyles,
  douplicate,
  deleteIconVisibility = "visible",
  shareIconVisibility = "visible",
  isSharedWithMe,
  counterText,
  openChangeFolderModal,
  shareId,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();

  const handleSharedPlanDelete = async () => {
    try {
      await deleteMethod(`/shared/${shareId}`);
      queryClient.invalidateQueries("listSharedPlans");
      queryClient.invalidateQueries("planGroups");
    } catch (error) {
      console.error("Failed to delete shared plan:", error);
    }
  };

  return (
    <Card
      _hover={{ bg: hoverStyles ? "gray.200" : "", cursor: "pointer" }}
      {...props}
      onClick={
        onCardCLick
          ? (e) => {
              e.stopPropagation();
              onCardCLick();
            }
          : () => {}
      }
    >
      <HStack justifyContent="space-between">
        {/* things on the right */}
        <HStack>
          <Text color="#1f3f93" fontFamily="Calibri (Body)" fontSize={18}>
            {title}
          </Text>
          <Text>{children}</Text>
          {counterText && (
            <Text color="#1f3f93" fontFamily="Calibri (Body)" fontSize={18} fontWeight="bold">
              {` - ${counterText}`}
            </Text>
          )}
        </HStack>

        {/* things on the left */}
        <HStack>
          {openChangeFolderModal && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                openChangeFolderModal();
              }}
              variant="link"
              aria-label="delete"
              fontSize="23px"
              minW={1}
              icon={<FaRegFolderOpen className="remove-on-print" />}
              h="5"
              visibility={deleteIconVisibility}
            />
          )}
          {share && (
            <IconButton
              visibility={shareIconVisibility}
              onClick={(e: any) => {
                e.stopPropagation();
                share();
              }}
              variant="link"
              aria-label="delete"
              fontSize="23px"
              minW={1}
              icon={<RiShareBoxLine className="remove-on-print" />}
              h="5"
            />
          )}
          {douplicate ? (
            <IconButton
              mx={2}
              onClick={(e) => {
                e.stopPropagation();
                douplicate();
              }}
              variant="link"
              aria-label="delete"
              fontSize="23px"
              minW={1}
              icon={<BiCopy />}
              h="5"
            />
          ) : null}
          {isSharedWithMe && <DeleteButton remove={handleSharedPlanDelete} />}
          {remove && <DeleteButton remove={remove} />}
          {isSharedWithMe && <p>{formatMessage({ id: "shared" })}</p>}
        </HStack>
      </HStack>
    </Card>
  );
};

export default NewItemCard;
