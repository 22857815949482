import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Modal,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  Input,
  Center,
  Select,
  useToast,
  HStack,
  Box,
  InputGroup,
} from "@chakra-ui/react";
import PrimaryButton from "src/components/PrimaryButton";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import usePlanApi from "../usePlanApi";
import { PlanAspect, PlanGoalAction } from "src/types";

const formatDate = (date: Date): string => {
  const d = new Date(date);
  const month = `${d.getMonth() + 1}`.padStart(2, "0");
  const day = `${d.getDate()}`.padStart(2, "0");
  const year = d.getFullYear();
  return `${year}-${month}-${day}`;
};

const initialFormData: PlanGoalAction = {
  name: "",
  complete: false,
  endDate: new Date(),
  note: "",
  quantity: "",
  startDate: new Date(),
  executor: "",
  counter: "",
};

const DatePickerInput = ({
  selectedDate,
  onChange,
  label,
}: {
  selectedDate: Date;
  onChange: (date: Date) => void;
  label: string;
}) => {
  return (
    <Box mb={4}>
      <Text mb={2}>{label}</Text>
      <ReactDatePicker
        selected={selectedDate}
        onChange={onChange}
        dateFormat="yyyy-MM-dd"
        customInput={<Input style={{ textAlign: "center" }} variant="flushed" width="100%" />}
        minDate={new Date()}
        isClearable={false}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        todayButton="Today"
      />
    </Box>
  );
};

function EditPlanModal({ isModalOpen, onClose, reload }) {
  const [addNewAspectName, setAddNewAspectName] = useState<string>("");
  const toast = useToast();
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [selectedAspectId, setSelectedAspectId] = useState<any>();
  const [selectedGoalId, setSelectedGoalId] = useState<any>();
  const { formatMessage } = useIntl();
  const [addNewGoalName, setAddNewGoaltName] = useState<any>("");
  const planId = +window.location.pathname.split("/")[4] || 0;

  const { planQuery, addActionMutation, addGoalMutation, addPlanAspectMutation } = usePlanApi(planId);

  const [formData, setFormData] = useState<PlanGoalAction>({
    ...initialFormData,
    startDate: selectedStartDate,
    endDate: selectedEndDate,
  });

  useEffect(() => {
    if (isModalOpen) {
      planQuery.refetch();
    }
  }, [isModalOpen]);

  const addAction = async (goalId: number, action: PlanGoalAction) => {
    if (!goalId) return;
    try {
      await addActionMutation(goalId, {
        ...formData,
        startDate: formatDate(selectedStartDate),
        endDate: formatDate(selectedEndDate),
      } as any);
      resetForm();
      toast({
        title: formatMessage({ id: "plan.goal.action.create" }),
        status: "success",
      });
      reload();
    } catch (error: any) {
      if (error.message[0]) {
        toast({
          title: formatMessage({ id: "plan.goal.action.create" }),
          description: error.message[0],
          status: "error",
        });
      }
    }
  };

  const addNewAspect = async (aspect: PlanAspect, planId: number) => {
    try {
      await addPlanAspectMutation(aspect, planId);
      toast({
        title: formatMessage({ id: "aspect.add.success" }),
        status: "success",
        isClosable: true,
      });
      reload();
    } catch (error: any) {
      toast({
        title: formatMessage({ id: "aspect.add.failed" }),
        description: error.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  const addGoal = async (name: string, aspectId) => {
    try {
      await addGoalMutation({ goal: { name }, aspectId: aspectId });
      toast({
        status: "success",
        title: formatMessage({ id: "goal.add.success" }),
      });
      reload();
    } catch (error) {
      toast({
        status: "success",
        title: formatMessage({ id: "goal.add.failed" }),
      });
    }
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setSelectedStartDate(new Date());
    setSelectedEndDate(new Date());
  };

  const getGoals = () => {
    const selectedAspect: any = planQuery.data?.aspects?.filter((aspect) => aspect.id == selectedAspectId);
    return (
      selectedAspect &&
      selectedAspect[0]?.goals?.map((goal) => (
        <option key={goal.id} value={goal.id}>
          {goal.name}
        </option>
      ))
    );
  };

  const onChange = (fieldName: string, value: any) => {
    setFormData((oldFormData) => ({ ...oldFormData, [fieldName]: value }));
  };

  return (
    <Modal isOpen={isModalOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage id="editplan" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs>
            <TabList>
              <Tab>
                <FormattedMessage id="purposes.create.add_aspect" />
              </Tab>
              <Tab>
                <FormattedMessage id="plan.aspect.goal.create" />
              </Tab>
              <Tab>
                <FormattedMessage id="plan.aspect.measure.create" />
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    addNewAspect({ name: addNewAspectName, goals: [] }, planId);
                    setAddNewAspectName("");
                  }}
                >
                  <Text
                    d="inline-block"
                    color="brand.900"
                    fontSize="18px"
                    fontWeight="bold"
                    fontFamily="Calibri (Body)"
                  >
                    <FormattedMessage id="purposes.create.add_aspect" />
                  </Text>
                  <Input
                    variant="flushed"
                    value={addNewAspectName}
                    onChange={(e) => setAddNewAspectName(e.target.value)}
                    id="addNewAspectInput"
                  />
                  <Center my={"32px"}>
                    <PrimaryButton type="submit" disabled={addNewAspectName.trim() == ""}>
                      <FormattedMessage id="save" defaultMessage="حفظ" />
                    </PrimaryButton>
                  </Center>
                </form>
              </TabPanel>
              <TabPanel>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    // addNewAspect({name:addNewAspectName,goals:[]},currentPlan.id!)
                    addGoal(addNewGoalName, selectedAspectId);
                    setAddNewGoaltName("");
                  }}
                >
                  <Select
                    onChange={(e) => {
                      setSelectedAspectId(e.target.value);
                    }}
                    value={selectedAspectId}
                    mb="5"
                    placeholder={formatMessage({ id: "selectAspect" })}
                  >
                    {planQuery.data?.aspects?.map((aspect) => {
                      return <option value={aspect.id}>{aspect.name}</option>;
                    })}
                  </Select>
                  <Text
                    d="inline-block"
                    color="brand.900"
                    fontSize="18px"
                    fontWeight="bold"
                    fontFamily="Calibri (Body)"
                  >
                    <FormattedMessage id="plan.aspect.goal.create" />
                  </Text>
                  <Input
                    variant="flushed"
                    value={addNewGoalName}
                    onChange={(e) => setAddNewGoaltName(e.target.value)}
                    id="addNewAspectInput"
                  />
                  <Center my={"32px"}>
                    <PrimaryButton type="submit" disabled={addNewGoalName.trim() == "" || !selectedAspectId}>
                      <FormattedMessage id="save" defaultMessage="حفظ" />
                    </PrimaryButton>
                  </Center>
                </form>
              </TabPanel>
              <TabPanel px="0">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    addAction(selectedGoalId, formData);
                  }}
                >
                  <Select
                    onChange={(e) => setSelectedAspectId(e.target.value)}
                    value={selectedAspectId}
                    mb="5"
                    placeholder={formatMessage({ id: "selectAspect" })}
                  >
                    {planQuery.data?.aspects?.map((aspect) => (
                      <option key={aspect.id} value={aspect.id}>
                        {aspect.name}
                      </option>
                    ))}
                  </Select>

                  <Select
                    isDisabled={!selectedAspectId}
                    onChange={(e) => setSelectedGoalId(e.target.value)}
                    value={selectedGoalId}
                    mb="5"
                    placeholder={formatMessage({ id: "selectGoal" })}
                  >
                    {getGoals()}
                  </Select>

                  <Text
                    d="inline-block"
                    color="brand.900"
                    fontSize="18px"
                    fontWeight="bold"
                    fontFamily="Calibri (Body)"
                    mb={4}
                  >
                    <FormattedMessage id="plan.aspect.measure.create" />
                  </Text>

                  <Input
                    onChange={(e) => onChange("name", e.target.value)}
                    value={formData.name}
                    variant="flushed"
                    placeholder={formatMessage({ id: "measure" })}
                    mb={4}
                  />

                  <Input
                    value={formData.quantity}
                    onChange={(e) => onChange("quantity", e.target.value)}
                    variant="flushed"
                    placeholder={formatMessage({ id: "amount" })}
                    mb={4}
                  />

                  <Input
                    value={formData.executor}
                    onChange={(e) => onChange("executor", e.target.value)}
                    variant="flushed"
                    placeholder={formatMessage({
                      id: "plan.goal.action.create.excutor",
                    })}
                    mb={4}
                  />

                  <Box mb={4}>
                    <DatePickerInput
                      selectedDate={selectedStartDate}
                      onChange={(date) => setSelectedStartDate(date)}
                      label={formatMessage({ id: "startDate" })}
                    />

                    <DatePickerInput
                      selectedDate={selectedEndDate}
                      onChange={(date) => setSelectedEndDate(date)}
                      label={formatMessage({ id: "endDate" })}
                    />
                  </Box>

                  <Center>
                    <PrimaryButton type="submit" disabled={!selectedGoalId || formData.name.trim() === ""}>
                      {formatMessage({ id: "save" })}
                    </PrimaryButton>
                  </Center>
                </form>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default EditPlanModal;
