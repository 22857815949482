import { DownloadIcon } from "@chakra-ui/icons";
import { Box, IconButton, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import useLocale from "src/providers/useLocale";
import { FiltersContext } from "./FiltersContext";
import { useSelector } from "src/store";
import usePlanApi from "../usePlanApi";
import * as XLSX from "xlsx-js-style";

const ExcelPlan = () => {
  const planId = +window.location.pathname.split("/")[4] || 0;
  const { planQuery } = usePlanApi(planId);
  const { endDate, excutor, startDate } = useContext(FiltersContext);
  const user = useSelector((state) => state.auth.user);
  const [locale] = useLocale();
  const isRTL = locale === "ar";

  // const toArabicNumbers = (str: string | number): string => {
  //   if (!str) return "";
  //   return isRTL ? String(str).replace(/[0-9]/g, (d) => "٠١٢٣٤٥٦٧٨٩"[d]) : String(str);
  // };

  const formatDate = (date: string | Date): string => {
    if (!date) return "";
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, "0");
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const getDefaultStyle = (isHeader = false) => ({
    font: {
      name: "Calibri",
      sz: isHeader ? 16 : 14,
      bold: isHeader,
    },
    alignment: {
      horizontal: isRTL ? "right" : "left",
      vertical: "center",
      wrapText: true,
      reading_order: isRTL ? 2 : 1,
    },
    // border: isHeader
    //   ? {
    //       top: { style: "thin" },
    //       bottom: { style: "thin" },
    //       left: { style: "thin" },
    //       right: { style: "thin" },
    //     }
    //   : null,
  });

  const createExcelData = () => {
    const data: any[] = [];
    const headers = isRTL
      ? ["الجانب", "الهدف", "الإجراء", "الكم", "الوزن", "المنفذ", "تاريخ البدء", "تاريخ الانتهاء", "الحالة"]
      : ["Aspect", "Goal", "Action", "Quantity", "Weight", "Executor", "Start Date", "End Date", "Status"];

    // General header style
    const titleStyle = {
      ...getDefaultStyle(true),
      font: { sz: 16, bold: true },
      alignment: { horizontal: "center", reading_order: isRTL ? 2 : 1 },
    };

    // Add the main headers
    const mainHeader = Array(10).fill({ v: "", s: titleStyle });
    mainHeader[0] = { v: planQuery.data?.name || "", s: titleStyle };
    data.push(mainHeader);

    // Add user name
    const userRow = Array(10).fill({ v: "", s: { ...titleStyle, font: { sz: 14, bold: false } } });
    userRow[0] = { v: user?.name || "", s: { ...titleStyle, font: { sz: 14, bold: false } } };
    data.push(userRow);

    // Add spacing
    data.push(Array(10).fill({ v: "", s: getDefaultStyle() }));

    // Add column headers once
    const headerRow = Array(10).fill({ v: "", s: getDefaultStyle(true) });
    headers.forEach((header, index) => {
      headerRow[index] = {
        v: header,
        s: {
          ...getDefaultStyle(true),
          // fill: { fgColor: { rgb: "87CEEB" } },
          alignment: { horizontal: "center" },
        },
      };
    });
    data.push(headerRow);

    // Process aspects and goals
    planQuery.data?.aspects?.forEach((aspect, aspectIndex) => {
      const aspectRow = Array(10).fill({ v: "", s: getDefaultStyle() });
      aspectRow[0] = {
        v: isRTL ? `الجانب ${aspectIndex + 1}: ${aspect.name}` : `Aspect ${aspectIndex + 1}: ${aspect.name}`,
        s: getDefaultStyle(),
      };
      data.push(aspectRow);

      aspect.goals?.forEach((goal, goalIndex) => {
        const goalRow = Array(10).fill({ v: "", s: getDefaultStyle() });
        goalRow[1] = {
          v: isRTL ? `الهدف ${goalIndex + 1}: ${goal.name}` : `Goal ${goalIndex + 1}: ${goal.name}`,
          s: getDefaultStyle(),
        };
        data.push(goalRow);

        const filteredActions = goal.actions?.filter((action: any) => {
          if (!excutor && !startDate && !endDate) return true;

          const conditions: any = [];
          if (excutor) {
            conditions.push(action.executor.trim().toLowerCase().includes(excutor.trim().toLowerCase()));
          }
          if (startDate) {
            conditions.push(formatDate(action.startDate) === formatDate(startDate));
          }
          if (endDate) {
            conditions.push(formatDate(action.endDate) === formatDate(endDate));
          }
          return conditions.length ? conditions.every((c) => c) : true;
        });

        filteredActions?.forEach((action: any) => {
          const actionRow = Array(10).fill({ v: "", s: getDefaultStyle() });
          const actionData = isRTL
            ? [
                action.name,
                action.quantity,
                action.weight ? action.weight : "-",
                action.executor,
                formatDate(action.startDate),
                formatDate(action.endDate),
                action.complete ? "تم" : "لم يتم",
              ]
            : [
                action.name,
                action.quantity,
                action.weight || "-",
                action.executor,
                formatDate(action.startDate),
                formatDate(action.endDate),
                action.complete ? "Completed" : "Not Completed",
              ];

          actionData.forEach((value, index) => {
            actionRow[index + 2] = {
              v: value,
              s: getDefaultStyle(),
            };
          });
          data.push(actionRow);
        });
      });
      data.push(Array(10).fill({ v: "", s: getDefaultStyle() }));
    });

    const ws = XLSX.utils.aoa_to_sheet(data);
    ws["!cols"] = Array(10).fill({ width: 20 });

    if (isRTL) {
      ws["!dir"] = "rtl";
      ws["!worksheet"] = { rightToLeft: true };
      ws["!xml"] = `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
        <worksheet xmlns="http://schemas.openxmlformats.org/spreadsheetml/2006/main" 
                  xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships">
          <sheetPr>
            <outlinePr summaryBelow="0"/>
          </sheetPr>
          <sheetFormatPr defaultRowHeight="15"/>
          <sheetViews>
            <sheetView rightToLeft="1" workbookViewId="0"/>
          </sheetViews>
        </worksheet>`;
    }

    return ws;
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    if (isRTL) {
      wb.Workbook = { Views: [{ RTL: true }] };
    }
    const ws = createExcelData();
    XLSX.utils.book_append_sheet(wb, ws, planQuery.data?.name || (isRTL ? "الخطة" : "Plan"));
    XLSX.writeFile(wb, `${planQuery.data?.name || (isRTL ? "الخطة" : "Plan")}.xlsx`);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      margin="0"
      padding="0"
      alignItems="center"
      py={1}
      position="relative"
      right="3.5"
    >
      <IconButton
        bg="#e6e6e6"
        fontSize="30px"
        width="70px"
        height="70px"
        aria-label={"Export to Excel"}
        color="brand.800"
        icon={<DownloadIcon />}
        onClick={exportToExcel}
      />
      <Text marginTop="10px" fontSize="18px" color="gray.900" maxWidth="100px" textAlign="center">
        {"Export to Excel"}
      </Text>
    </Box>
  );
};

export default ExcelPlan;
